import React from 'react'

export const Loading = () => {
    return (
        <>
            <div className=" shadow rounded-md p-4 w-full mx-auto">
                <div className="animate-pulse flex space-x-4">
                    <div className="flex-1 space-y-6 py-1">
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="h-2 bg-slate-200 rounded" />
                    </div>
                </div>
            </div>
        </>
    )
}
