/* eslint-disable no-restricted-globals */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Loading } from "../../../components/Loading/Loading";
import { RootState } from "../../../store/store";

type Inputs = {
  fileTxt?: any;
};

export const Transaksi = () => {
  const globalStateUser: any = useSelector((state: RootState) => {
    return state.userData;
  });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Inputs>();
  const token = globalStateUser.token;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState({
    from: 0,
    to: 0,
    total: 0,
  });
  const [pageNav, setPageNav] = useState({
    preffPage: "",
    nextPage: "",
  });

  const rupiahConvert = (intValue: any) => {
    let reverse = intValue.toString().split("").reverse().join(""),
      ribuan = reverse.match(/\d{1,3}/g);
    ribuan = ribuan.join(".").split("").reverse().join("");
    return ribuan;
  };

  useEffect(() => {
    if (globalStateUser.user?.role_id !== "accounting") {
      navigate("/product");
    }
    setIsLoading(true);
    axios
      .get(
        (process.env.REACT_APP_ACCOUNTING_GET_TRX_PAID as string) +
          `?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setTotalData({
          ...totalData,
          from: response.data.data.from,
          to: response.data.data.to,
          total: response.data.data.total,
        });
        setPageNav({
          ...pageNav,
          preffPage: response.data.data.prev_page_url,
          nextPage: response.data.data.next_page_url,
        });
        setData(response.data.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, [page]);

  return (
    <>
      <div className="text-2xl text-center font-bold">Transaksi VA</div>
      <br />
      <div className="flex justify-end mb-2">
        <Controller
          control={control}
          rules={{ required: true }}
          name="fileTxt"
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <>
              <input
                type="file"
                id="ktp"
                hidden
                onChange={(e: any) => {
                  const files = e.target.files;
                  const file = files[0];
                  let reader: any = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    Swal.fire({
                      title: "Yakin mau mengupload file mutasi ?",
                      showCancelButton: true,
                      confirmButtonText: "Upload",
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        Swal.fire("Please wait!");
                        Swal.showLoading();
                        const dataTxt = {
                          base64_log_file: reader.result.replace(
                            /^data:text\/[a-z]+;base64,/,
                            ""
                          ),
                        };
                        axios
                          .post(
                            process.env
                              .REACT_APP_UPLOAD_BANK_LOG_FILE as string,
                            dataTxt,
                            {
                              headers: {
                                Authorization: `Bearer ${globalStateUser.token}`,
                              },
                            }
                          )
                          .then((response) => {
                            Swal.fire("File telah terupload!", "", "success");
                            location.reload();
                          })
                          .catch((error) => {
                            setIsLoading(false);
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              text: "Something went wrong!",
                            });
                            console.log(error);
                          });
                      } else if (result.isDenied) {
                        Swal.fire("Changes are not saved", "", "info");
                        e.target.files = null;
                      }
                    });
                  };
                }}
              />
              <label className="btn btn-primary" htmlFor="ktp">
                Upload Mutasi Bank
              </label>
            </>
          )}
        />
      </div>
      <div className="overflow-x-auto">
        {isLoading === true ? (
          <Loading />
        ) : (
          <table className="table table-compact w-full text-center border-collapse border border-slate-400">
            <thead>
              <tr>
                <th className="sticky left-0 z-10 border border-b-slate-300 border-r-0" />
                <th className="border border-slate-300 border-l-0">
                  Tanggal Paid
                </th>
                <th className="border border-slate-300">Kode Transaksi Paid</th>
                <th className="border border-slate-300">Toko</th>
                <th className="border border-slate-300">Nomor VA</th>
                <th className="border border-slate-300">Nominal Transfer</th>
                <th className="border border-slate-300">Bank Cash In</th>
                <th className="border border-slate-300">
                  Tanggal Bank Transfer
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((data: any, idx) => {
                return (
                  <tr key={idx}>
                    <td />
                    <td>{data.pay?.paid_date}</td>
                    <td>{data.code}</td>
                    <td>{data.user?.shop?.name}</td>
                    <td>{data.pay?.account_number}</td>
                    <td>Rp. {rupiahConvert(data.pay?.pay_amount)}</td>
                    <td>{data.pay?.bank_transfer_amount}</td>
                    <td>{data.pay?.bank_transfer_date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center mt-2">
        <div>
          <p>
            Showing {totalData.from} to {totalData.to} of {totalData.total}{" "}
            entries
          </p>
        </div>
        <div>
          <div className="btn-group">
            <button
              className={
                pageNav.preffPage ? "btn btn-primary" : `btn btn-disabled`
              }
              onClick={() => {
                setPage(page - 1);
              }}
            >
              «
            </button>
            <button className="btn btn-primary">Page {page}</button>
            <button
              className={
                pageNav.nextPage ? "btn btn-primary" : `btn btn-disabled`
              }
              onClick={() => {
                setPage(page + 1);
              }}
            >
              »
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
