import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { LoginData } from "../../interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { login, storeToken } from "../../store/userSlice";
import { RootState } from "../../store/store";

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalStateUser: any = useSelector((state: RootState) => {
    return state.userData;
  });
  const token = globalStateUser.token;

  useEffect(() => {
    if (token) {
      if (globalStateUser.user?.role_id === "accounting") {
        navigate("/transaksi");
      } else {
        navigate("/daftarpo");
      }
    }
  }, []);

  const onSubmit = handleSubmit((data: any) => {
    const dataLogin = {
      email: data.email,
      password: data.password,
    };
    axios
      .post(process.env.REACT_APP_LOGIN as string, dataLogin)
      .then((response) => {
        dispatch(login(response.data.data.user));
        dispatch(storeToken(response.data.data.token));
        navigate("/daftarpo");
      })
      .catch((error) => {
        console.log(error);
      });
  });

  return (
    <>
      <div className="hero min-h-screen bg-base-200">
        <div className="flex-col hero-content lg:flex-row-reverse">
          <div className="text-center lg:text-left">
            <h1 className="text-5xl font-bold">Login!</h1>
            <p className="py-6">Jatim Park Distribution Center - Warehouse</p>
          </div>
          <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
            <div className="card-body">
              <form onSubmit={onSubmit}>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Email</span>
                  </label>
                  <input
                    type="text"
                    placeholder="email"
                    className={`${
                      !errors.email?.type
                        ? "input input-bordered"
                        : "input border-2 border-error"
                    }`}
                    {...register("email", { required: true })}
                  />
                  <div className="label justify-start">
                    {errors.email ? (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="text-error mr-2"
                      />
                    ) : (
                      ""
                    )}
                    <span className="text-error text-sm font-bold">
                      {errors.email?.type === "required" && "Email required"}
                    </span>
                  </div>
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Password</span>
                  </label>
                  <input
                    type="password"
                    placeholder="password"
                    className={`${
                      !errors.password?.type
                        ? "input input-bordered"
                        : "input border-2 border-error"
                    }`}
                    {...register("password", { required: true })}
                  />
                  <div className="label justify-start">
                    {errors.password ? (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="text-error mr-2"
                      />
                    ) : (
                      ""
                    )}
                    <span className="text-error text-sm font-bold">
                      {errors.password?.type === "required" &&
                        "Password required"}
                    </span>
                  </div>
                  <label className="label"></label>
                </div>
                <div className="form-control mt-6">
                  <button className="btn btn-primary" type="submit">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
