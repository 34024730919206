/* eslint-disable no-restricted-globals */
import axios from "axios";
import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Select from "react-select";
import Swal from "sweetalert2";
import { setProductId } from "../../../store/userSlice";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../components/Loading/Loading";
import Modal from "react-modal";
Modal.setAppElement("#root");

export interface DataOption {
  value: string;
  label: string;
}

export const Product = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const globalStateUser: any = useSelector((state: RootState) => {
    return state.userData;
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = globalStateUser.token;
  const [data, setData] = useState([]);
  const [dataImageBase64, setDataImageBase64] = useState("");
  const [options, setOptions] = useState<DataOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isOpenModalNewProduct, setIsOpenModalNewProduct] = useState(false);
  function toggleModalNewProduct(data?: any) {
    setIsOpenModalNewProduct(!isOpenModalNewProduct);
    if (data.id) {
      dispatch(setProductId(data.id));
      setDataImageBase64(data.image);
      reset({
        type_id: data.type_id,
        category: data.category,
        name: data.name,
        stock_unit: data.stock_unit,
        supplier_price: parseInt(data.supplier_price),
        sale_price: parseInt(data.sale_price),
      });
    } else {
      dispatch(setProductId(null));
      reset({
        type_id: "",
        category: "",
        name: "",
        stock_unit: "",
        supplier_price: 0,
        sale_price: 0,
        image: "",
      });
    }
  }

  const rupiahConvert = (intValue: any) => {
    let reverse = intValue.toString().split("").reverse().join(""),
      ribuan = reverse.match(/\d{1,3}/g);
    ribuan = ribuan.join(".").split("").reverse().join("");
    return ribuan;
  };

  useEffect(() => {
    setIsLoading(true);
    if (globalStateUser.user?.role_id === "accounting") {
      navigate("/transaksi");
    }
    axios
      .get(process.env.REACT_APP_ACCOUNTING_GET_ALL_TYPE as string, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        response.data?.data?.forEach((data: any) => {
          setOptions((prevState) => [
            ...prevState,
            {
              value: data.id,
              label: data.name,
            },
          ]);
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    axios
      .get(process.env.REACT_APP_ACCOUNTING_GET_PRODUCTS as string, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onChange = (e: any) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };

  const onLoad = (fileString: any) => {
    setDataImageBase64(fileString);
  };

  const getBase64 = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };

  const onSubmit = (data: any) => {
    const postData = {
      name: data.name,
      type_id: data.type_id,
      supplier_id: data.supplier_id.value,
      category: data.category,
      stock_unit: data.stock_unit,
      supplier_price: parseInt(data.supplier_price),
      sale_price: parseInt(data.sale_price),
      image: dataImageBase64.replace(/^data:image\/[a-z]+;base64,/, ""),
    };
    if (!globalStateUser.productId) {
      axios
        .post(
          process.env.REACT_APP_ACCOUNTING_GET_PRODUCTS as string,
          postData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          location.reload();
          Swal.fire({
            title: `Product Telah Ditambahkan!`,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .put(
          (process.env.REACT_APP_ACCOUNTING_GET_PRODUCTS as string) +
            `/${globalStateUser.productId}`,
          postData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          location.reload();
          Swal.fire({
            title: `Product Telah Diedit!`,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const fetchDataSupplierList = (inputValue: any, callback: any) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(
          (process.env.REACT_APP_GET_ALL_SUPPLIER as string) +
            `?filter[name]=${inputValue}`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            const tempArray: any = [];
            res.data.forEach((element: any) => {
              tempArray.push({
                label: `${element.name}`,
                value: element.id,
              });
            });
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  return (
    <>
      <div>
        <Modal
          isOpen={isOpenModalNewProduct}
          onRequestClose={toggleModalNewProduct}
          contentLabel="My dialog"
          className="mymodal w-11/12 lg:w-6/12 lg:h-6/6 overflow-y-auto max-h-full"
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
          shouldCloseOnEsc={false}
        >
          <div>
            <label
              htmlFor="my-modal-3"
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={toggleModalNewProduct}
            >
              ✕
            </label>
            <div>
              <h3 className="font-bold text-xl mb-3">Form Product</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Type</span>
                  </label>
                  <Controller
                    control={control}
                    name="type_id"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        value={options.find((c) => c.value === value)}
                        name={name}
                        options={options}
                        onChange={(selectedOption: any) => {
                          onChange(selectedOption.value);
                        }}
                      />
                    )}
                  />
                  <label className="label">
                    <span className="label-text">Supplier</span>
                  </label>
                  <Controller
                    control={control}
                    name="supplier_id"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <AsyncSelect
                        noOptionsMessage={() => "Type to search a SO list"}
                        {...register("supplier_id")}
                        value={value}
                        loadOptions={fetchDataSupplierList}
                        placeholder={`Supplier`}
                        onChange={(e: any) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors.supplier_id ? (
                    <span className="text-sm text-red-700">
                      Field is required!
                    </span>
                  ) : null}
                  <label className="label">
                    <span className="label-text">Category</span>
                  </label>
                  <select
                    className="select select-bordered w-full"
                    {...register("category", { required: true })}
                  >
                    <option disabled selected>
                      Category
                    </option>
                    <option value="makanan">Makanan</option>
                    <option value="minuman">Minuman</option>
                  </select>
                  <label className="label">
                    <span className="label-text">Nama Product</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Nama product..."
                    className="input input-bordered w-full"
                    {...register("name", { required: true })}
                  />
                  <label className="label">
                    <span className="label-text">Stock Unit</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Stock unit..."
                    className="input input-bordered w-full"
                    {...register("stock_unit", { required: true })}
                  />
                  <label className="label">
                    <span className="label-text">Supplier Price</span>
                  </label>
                  <input
                    type="number"
                    placeholder="Supplier price..."
                    className="input input-bordered w-full"
                    {...register("supplier_price", { required: true })}
                  />
                  <label className="label">
                    <span className="label-text">Sale Price</span>
                  </label>
                  <input
                    type="number"
                    placeholder="Sale price..."
                    className="input input-bordered w-full"
                    {...register("sale_price", { required: true })}
                  />
                  <label className="label">
                    <span className="label-text">Gambar Product</span>
                  </label>
                  <input type="file" onChange={onChange} />
                  <button className="btn btn-primary mt-2" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        <div className="flex justify-end">
          <div className="mb-2">
            {/* The button to open modal */}
            <label
              className="btn btn-primary modal-button"
              onClick={toggleModalNewProduct}
            >
              Tambah Product
            </label>
            {/* Put this part before </body> tag */}
          </div>
        </div>
        {isLoading === true ? (
          <Loading />
        ) : (
          <div className="overflow-x-auto border border-slate-200 rounded-md">
            <table className="table w-full">
              {/* head */}
              <thead>
                <tr>
                  <th />
                  <th>Name</th>
                  <th>Category</th>
                  <th>Supplier</th>
                  <th>Supplier Price</th>
                  <th>Sale Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((data: any, idx: any) => {
                  return (
                    <tr key={idx} className="hover">
                      <th />
                      <td>{data.name}</td>
                      <td>{data.category}</td>
                      <td>{data.supplier.name}</td>
                      <td>Rp. {rupiahConvert(data.supplier_price)}</td>
                      <td>Rp. {rupiahConvert(data.sale_price)}</td>
                      <td>
                        {/* The button to open modal */}
                        <label
                          htmlFor="my-modal-4"
                          className="btn btn-info modal-button"
                          onClick={() => toggleModalNewProduct(data)}
                        >
                          Edit
                        </label>
                        {/* Put this part before </body> tag */}
                        <input
                          type="checkbox"
                          id="my-modal-4"
                          className="modal-toggle"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};
