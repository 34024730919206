/* eslint-disable no-restricted-globals */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { RootState } from "../../../store/store";
import { setTypeId } from "../../../store/userSlice";
import Modal from "react-modal";
import { Loading } from "../../../components/Loading/Loading";
Modal.setAppElement("#root");

export const ProductType = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const globalStateUser: any = useSelector((state: RootState) => {
    return state.userData;
  });
  const token = globalStateUser.token;
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);

  const [isOpenModalNewType, setIsOpenModalNewType] = useState(false);
  function toggleModalNewType(data?: any) {
    setIsOpenModalNewType(!isOpenModalNewType);
    if (data.id) {
      dispatch(setTypeId(data.id));
      reset({
        name: data.name,
      });
    } else {
      dispatch(setTypeId(null));
      reset({
        name: "",
      });
    }
  }

  useEffect(() => {
    setisLoading(true);
    if (globalStateUser.user?.role_id === "accounting") {
      navigate("/transaksi");
    }
    axios
      .get(process.env.REACT_APP_TYPE as string, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  }, []);

  const onSubmit = (data: any) => {
    const postData = {
      name: data.name,
    };
    if (!globalStateUser.typeId) {
      axios
        .post(process.env.REACT_APP_TYPE as string, postData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          location.reload();
          Swal.fire({
            title: `Type Telah Ditambahkan!`,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .put(
          (process.env.REACT_APP_TYPE as string) + `/${globalStateUser.typeId}`,
          postData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          location.reload();
          Swal.fire({
            title: `Type Telah Diedit!`,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpenModalNewType}
        onRequestClose={toggleModalNewType}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-6/12 lg:h-6/6 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
        shouldCloseOnEsc={false}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalNewType}
          >
            ✕
          </label>
          <div>
            <div>
              <h3 className="font-bold text-xl mb-3">Form Type</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Nama Type</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Nama product..."
                    className="input input-bordered w-full"
                    {...register("name", { required: true })}
                  />
                  <button className="btn btn-primary mt-2" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <div>
        <div className="flex justify-end">
          <div className="mb-2">
            {/* The button to open modal */}
            <label
              className="btn btn-primary modal-button"
              onClick={toggleModalNewType}
            >
              Tambah Type
            </label>
            {/* Put this part before </body> tag */}
          </div>
        </div>
        {isLoading === true ? (
          <Loading />
        ) : (
          <div className="overflow-x-auto border border-slate-200 rounded-md">
            <table className="table w-full">
              {/* head */}
              <thead>
                <tr>
                  <th />
                  <th>ID</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((data: any, idx: any) => {
                  return (
                    <tr key={idx} className="hover">
                      <th />
                      <td>{data.id}</td>
                      <td>{data.name}</td>
                      <td>
                        {/* The button to open modal */}
                        <label
                          htmlFor="my-modal-4"
                          className="btn btn-info modal-button"
                          onClick={() => toggleModalNewType(data)}
                        >
                          Edit
                        </label>
                        {/* Put this part before </body> tag */}
                        <input
                          type="checkbox"
                          id="my-modal-4"
                          className="modal-toggle"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};
