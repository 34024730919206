import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { RootState } from "../../store/store";
import { logout } from "../../store/userSlice";

export const Drawer = () => {
  const globalStateUser: any = useSelector((state: RootState) => {
    return state.userData;
  });
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <div className="h-screen drawer drawer-mobile w-full">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="flex flex-col drawer-content">
          {/* Page content here */}

          {/**
           * Navbar
           */}
          <div className="navbar bg-base-100 shadow-lg">
            <div className="flex-none">
              <label
                className="btn btn-square btn-ghost lg:hidden"
                htmlFor="my-drawer-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="inline-block w-5 h-5 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </label>
            </div>
            <div className="flex-1">
              {/* <a className="btn btn-ghost normal-case text-xl">daisyUI</a> */}
            </div>
            <div className="flex-none">
              <p className="mr-2">
                {globalStateUser?.user?.name}{" "}
                <span className="font-bold italic">
                  ({globalStateUser?.user?.role_id})!
                </span>
              </p>
              <div className="dropdown dropdown-end">
                <label tabIndex={0} className="m-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="inline-block w-5 h-5 stroke-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                    />
                  </svg>
                </label>
                <ul
                  tabIndex={0}
                  className="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52"
                >
                  {/* <li>
                    <a>Change Location</a>
                  </li> */}
                  <li>
                    <a onClick={handleLogout}>Logout</a>
                  </li>
                </ul>
              </div>
              {/* <button className="btn btn-square btn-ghost">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" /></svg>
                            </button> */}
            </div>
          </div>
          {/**
           * End Navbar
           */}
          <div className="p-5">
            <Outlet />
          </div>
        </div>
        <div className="drawer-side shadow-lg">
          <label htmlFor="my-drawer-2" className="drawer-overlay" />
          <ul
            className="menu p-4 overflow-y-auto w-64 bg-base-100 text-base-content"
            style={{ zIndex: 0 }}
          >
            {/* Sidebar content here */}
            <li>
              <p className="text-lg font-bold text-primary">
                {globalStateUser.user?.role_id === "accounting"
                  ? "Accounting"
                  : "Distribution Center"}
              </p>
            </li>
            {globalStateUser.user?.role_id === "accounting" ? (
              <>
                <li>
                  <Link to="/input-stock">Input Stock</Link>
                </li>
                <li>
                  <Link to="/transaksi">Transaksi VA</Link>
                </li>
                <li>
                  <Link to="/penjualan">Penjualan</Link>
                </li>
                <li>
                  <Link to="/daftarpo">Daftar PO</Link>
                </li>
                <li>
                  <Link to="/pyrq">PYRQ</Link>
                </li>
                {/* <li>
                  <Link to="/bpk">BPK</Link>
                </li> */}
              </>
            ) : (
              <>
                <li>
                  <Link to="/product">Product</Link>
                </li>
                <li>
                  <Link to="/type">Product Type</Link>
                </li>
                <li>
                  <Link to="/supplier">Supplier</Link>
                </li>
                {/* <li>
                  <Link to="/history">History</Link>
                </li>
                <li>
                  <Link to="/proyeksi">Proyeksi</Link>
                </li>
                <li>
                  <Link to="/weekstoorder">Weeks To Order</Link>
                </li> */}
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};
