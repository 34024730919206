import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "userData",
  initialState: {
    user: null,
    token: null,
    currentWeek: null,
    productId: null,
    typeId: null,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    storeToken: (state, action) => {
      state.token = action.payload;
    },
    setCurrentWeek: (state, action) => {
      state.currentWeek = action.payload;
    },
    setProductId: (state, action) => {
      state.productId = action.payload;
    },
    setTypeId: (state, action) => {
      state.typeId = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.currentWeek = null;
    },
  },
});

export const userReducer = userSlice.reducer;

export const {
  login,
  logout,
  storeToken,
  setCurrentWeek,
  setProductId,
  setTypeId,
} = userSlice.actions;
