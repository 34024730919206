/* eslint-disable no-restricted-globals */
import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { RootState } from "../../../store/store";
import {
  Controller,
  useForm,
  useFieldArray,
  SubmitHandler,
} from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { rupiahConvert } from "../../../helper/rupiahConvert";
import { Loading } from "../../../components/Loading/Loading";
Modal.setAppElement("#root");

export interface DataOption {
  value: string;
  label: string;
}

type items = {
  product_id: string;
  qty: number;
};

type Inputs = {
  branch: string;
  supplier_id: string;
  delivery_date_requested: Date;
  delivery_date_promised: Date;
  issued_by: string;
  acknowledge_by: string;
  items: items[];
};

export const DaftarPO = () => {
  const globalStateUser: any = useSelector((state: RootState) => {
    return state.userData;
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [optionsLocation, setOptionsLocation] = useState<DataOption[]>([
    { value: "JTP1", label: "Jatim Park 1" },
    { value: "JTP2", label: "Jatim Park 2" },
    { value: "JTP3", label: "Jatim Park 3" },
    { value: "ECO", label: "Eco Green Park" },
    { value: "SENYUM", label: "Senyum World Hotel" },
    { value: "MGG", label: "Milenial Glow Garden" },
    { value: "PFP", label: "Predator Fun Park" },
    { value: "POHONINN", label: "Pohon Inn Hotel" },
    { value: "MA", label: "Museum Angkut" },
    { value: "BALOGA", label: "Batu Love Garden" },
  ]);
  const navigate = useNavigate();
  const token = globalStateUser.token;
  const [dataPo, setDataPo] = useState([]);
  const [pages, setPages] = useState<any>({});
  const [totalData, setTotalData] = useState({
    from: 0,
    to: 0,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [locationFilter, setLocationFilter] = useState("");
  const [search, setSearch] = useState({
    text: "",
    type: "code",
  });
  const [supplierForSearchProduct, setSupplierForSearchProduct] = useState("");
  //react hook form useFeildArray
  const {
    register: registerNewPO,
    handleSubmit: handleSubmitNewPO,
    control: controlNewPO,
    formState: { errors: errorsNewPO },
    setValue: setValueNewPO,
    getValues: getValueNewPO,
    reset: resetValueNewPO,
  } = useForm<Inputs>({
    defaultValues: {
      items: [
        {
          product_id: "",
          qty: 0,
        },
      ],
    },
  });
  const {
    fields: fieldProduct,
    append: appendProduct,
    remove: removeProduct,
  } = useFieldArray({
    control: controlNewPO,
    name: "items",
  } as never);

  const [isOpenModalNewPo, setIsOpenModalNewPo] = useState(false);
  function toggleModalNewPo(data?: any) {
    setIsOpenModalNewPo(!isOpenModalNewPo);
  }

  useEffect(() => {
    setIsLoading(true);
    if (globalStateUser.user?.role_id !== "accounting") {
      navigate("/product");
    }
    axios
      .get(
        (process.env.REACT_APP_PURCHASE_ORDER as string) +
          `?page=${parseInt(pages?.current_page)}&filter[${search.type}]=${
            search.text
          }&filter[issued_date]=&start=${date.startDate}&end=${
            date.endDate
          }&filter[branch]=${locationFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setDataPo(response.data.data.data);
        setTotalData({
          ...totalData,
          from: response.data.data.from,
          to: response.data.data.to,
          total: response.data.data.total,
        });
        setPages(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [search, date.endDate, locationFilter]);

  const handleClick = (data: any) => {
    axios
      .get((process.env.REACT_APP_PURCHASE_ORDER as string) + `/${data?.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Swal.fire({
          title: "Detail",
          width: "auto",
          html: `
                <style type="text/css">
                .tg  {border-collapse:collapse;border-color:#9ABAD9;border-spacing:0;}
                .tg td{background-color:#EBF5FF;border-color:#9ABAD9;border-style:solid;border-width:0px;color:#444;
                font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;word-break:normal;}
                .tg th{background-color:#409cff;border-color:#9ABAD9;border-style:solid;border-width:0px;color:#fff;
                font-family:Arial, sans-serif;font-size:14px;font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
                .tg .tg-9wq8{border-color:inherit;text-align:center;vertical-align:middle}
                </style>
                <table class="tg">
                <thead>
                    <tr>
                    <th class="tg-0lax">No</th>
                    <th class="tg-0lax">Products</th>
                    <th class="tg-0lax">Qty</th>
                    <th class="tg-0lax">Price</th>
                    <th class="tg-0lax">Total</th>
                    </tr>
                </thead>
                <tbody>
                ${response.data?.data?.items?.map((data: any, idx: any) => {
                  return `
                    <tr>
                        <td class="tg-0lax">${idx + 1}</td>
                        <td class="tg-0lax">${data?.product?.name}</td>
                        <td class="tg-0lax">${data?.qty}</td>
                        <td class="tg-0lax">Rp. ${rupiahConvert(
                          data?.price
                        )}</td>
                        <td class="tg-0lax">Rp. ${rupiahConvert(
                          data?.total_price
                        )}</td>
                    </tr>
                    `;
                })}                          
                </tbody>
                </table>
                `,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNewPo: SubmitHandler<Inputs> = (data: any) => {
    const submitData = {
      branch: data.branch,
      supplier_id: data.supplier_id.value,
      delivery_date_requested: data.delivery_date_requested,
      delivery_date_promised: data.delivery_date_promised,
      issued_by: data.issued_by,
      acknowledge_by: data.acknowledge_by,
      items: data.items.map((data: any) => {
        return {
          product_id: data.product_id.value,
          qty: parseInt(data.qty),
        };
      }),
    };
    axios
      .post(process.env.REACT_APP_PURCHASE_ORDER as string, submitData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Stock telah di tambahkan!",
          showConfirmButton: false,
          timer: 1500,
        });
        setIsOpenModalNewPo(false);
        location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchDataProductList = (inputValue: any, callback: any) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(
          (process.env.REACT_APP_GET_ALL_PRODUCT_NO_IMAGES as string) +
            `?filter[name]=${inputValue}&filter[supplier_id]=${supplierForSearchProduct}`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            const tempArray: any = [];
            res.data.forEach((element: any) => {
              tempArray.push({
                label: `${element.name}`,
                value: element.id,
              });
            });
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  const fetchDataSupplierList = (inputValue: any, callback: any) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(
          (process.env.REACT_APP_GET_ALL_SUPPLIER as string) +
            `?filter[name]=${inputValue}`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            const tempArray: any = [];
            res.data.forEach((element: any) => {
              tempArray.push({
                label: `${element.name}`,
                value: element.id,
              });
            });
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  const prevPage = () => {
    axios
      .get(
        (process.env.REACT_APP_PURCHASE_ORDER as string) +
          "?page=" +
          (parseInt(pages?.current_page) - 1),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setTotalData({
          ...totalData,
          from: response.data.data.from,
          to: response.data.data.to,
          total: response.data.data.total,
        });
        setDataPo(response.data.data.data);
        setPages(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const nextPage = () => {
    if (pages.next_page_url !== null) {
      axios
        .get(
          (process.env.REACT_APP_PURCHASE_ORDER as string) +
            "?page=" +
            parseInt(pages?.current_page + 1),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setTotalData({
            ...totalData,
            from: response.data.data.from,
            to: response.data.data.to,
            total: response.data.data.total,
          });
          setDataPo(response.data.data.data);
          setPages(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpenModalNewPo}
        onRequestClose={toggleModalNewPo}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-6/12 lg:h-6/6 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
        shouldCloseOnEsc={false}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalNewPo}
          >
            ✕
          </label>
          <div>
            <form onSubmit={handleSubmitNewPO(handleNewPo)}>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Branch</span>
                </label>
                <Controller
                  control={controlNewPO}
                  name="branch"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      value={optionsLocation.find((c) => c.value === value)}
                      {...registerNewPO("branch", { required: true })}
                      name={name}
                      options={optionsLocation}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      onChange={(selectedOption: any) => {
                        setValueNewPO("branch", selectedOption.value);
                      }}
                    />
                  )}
                />
                {errorsNewPO.branch ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <label className="label">
                  <span className="label-text">Supplier</span>
                </label>
                <Controller
                  control={controlNewPO}
                  name="supplier_id"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <AsyncSelect
                      noOptionsMessage={() => "Type to search a SO list"}
                      {...registerNewPO("supplier_id")}
                      value={value}
                      loadOptions={fetchDataSupplierList}
                      placeholder={`Supplier`}
                      onChange={(e: any) => {
                        onChange(e);
                        setSupplierForSearchProduct(e.value);
                      }}
                    />
                  )}
                />
                {errorsNewPO.supplier_id ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <label className="label">
                  <span className="label-text">Delivery Date Requested</span>
                </label>
                <Controller
                  control={controlNewPO}
                  name="delivery_date_requested"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <input
                      type="date"
                      {...registerNewPO("delivery_date_requested", {
                        required: true,
                      })}
                      className="border rounded-md p-[5px] border-slate-300"
                      onChange={(e: any) => {
                        // setDate({ ...date, startDate: e.target.value });
                      }}
                    />
                  )}
                />
                {errorsNewPO.delivery_date_requested ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <label className="label">
                  <span className="label-text">Delivery Date Promised</span>
                </label>
                <Controller
                  control={controlNewPO}
                  name="delivery_date_promised"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <input
                      type="date"
                      {...registerNewPO("delivery_date_promised", {
                        required: true,
                      })}
                      className="border rounded-md p-[5px] border-slate-300"
                      onChange={(e: any) => {
                        // setDate({ ...date, startDate: e.target.value });
                      }}
                    />
                  )}
                />
                {errorsNewPO.delivery_date_promised ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <label className="label">
                  <span className="label-text">Issued By</span>
                </label>
                <input
                  type="text"
                  {...registerNewPO("issued_by", { required: true })}
                  placeholder="Type here"
                  className="border border-slate-300 rounded-md p-[5px]"
                />
                {errorsNewPO.issued_by ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <label className="label">
                  <span className="label-text">Acknowledge By</span>
                </label>
                <input
                  type="text"
                  {...registerNewPO("acknowledge_by", { required: true })}
                  placeholder="Type here"
                  className="border border-slate-300 rounded-md p-[5px]"
                />
                {errorsNewPO.acknowledge_by ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                {fieldProduct.map((field, index) => {
                  return (
                    <div key={field.id}>
                      <div className="flex flex-row justify-between my-2">
                        <div>
                          {index > 0 ? null : (
                            <button
                              type="button"
                              className="btn btn-sm bg-pink-700 border-0"
                              onClick={() =>
                                appendProduct({
                                  product_id: "",
                                  qty: 0,
                                })
                              }
                            >
                              Add Item
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row justify-between gap-4">
                        <div className="basis-1/2">
                          <label className="label">
                            <span className="label-text">product</span>
                          </label>
                          <Controller
                            control={controlNewPO}
                            {...registerNewPO(`items.${index}.product_id`)}
                            rules={{ required: true }}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                            }) => (
                              <AsyncSelect
                                className="w-full"
                                noOptionsMessage={() =>
                                  "Type to search a product"
                                }
                                isDisabled={
                                  !supplierForSearchProduct ? true : false
                                }
                                value={value}
                                loadOptions={fetchDataProductList}
                                placeholder={`Product`}
                                onChange={(e: any) => {
                                  onChange(e);
                                }}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            )}
                          />
                          {errorsNewPO.items ? (
                            <span className="text-sm text-red-700">
                              Products is required!
                            </span>
                          ) : null}
                        </div>
                        <div className="basis-1/2">
                          <div className="form-control w-full max-w-xs">
                            <label className="label">
                              <span className="label-text">qty</span>
                            </label>
                            <input
                              type="text"
                              disabled={
                                !supplierForSearchProduct ? true : false
                              }
                              {...registerNewPO(`items.${index}.qty`)}
                              placeholder="Type here"
                              className="border rounded-md p-[5px] border-slate-300"
                            />
                            {errorsNewPO.items ? (
                              <span className="text-sm text-red-700">
                                Products is required!
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 float-right">
                        <button
                          type="button"
                          onClick={() => removeProduct(index)}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="text-red-700"
                          />
                        </button>
                      </div>
                      {/* {index === 0 ? null : (
                    )} */}
                    </div>
                  );
                })}
                <button className="btn btn-primary w-full mt-4" type="submit">
                  Add Op
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <div className="text-2xl text-center font-bold">Daftar PO</div>
      <div className="flex flex-col md:flex-row justify-between items-center gap-2">
        <div className="basis-2/4">
          <button
            className="btn btn-primary mt-4 w-full"
            onClick={toggleModalNewPo}
          >
            Add Po
          </button>
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Start Date</span>
          </label>
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <input
                type="date"
                className="border rounded-md p-[5px] border-slate-300"
                onChange={(e: any) => {
                  setDate({ ...date, startDate: e.target.value });
                }}
              />
            )}
          />
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">End Date</span>
          </label>
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <input
                disabled={date.startDate === ""}
                type="date"
                className="border rounded-md p-[5px] border-slate-300"
                onChange={(e: any) => {
                  setDate({ ...date, endDate: e.target.value });
                }}
              />
            )}
          />
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Location</span>
          </label>
          <Controller
            control={control}
            name="location_id"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                isClearable
                value={optionsLocation.find((c) => c.value === value)}
                name={name}
                options={optionsLocation}
                onChange={(selectedOption: any) => {
                  setLocationFilter(selectedOption.value);
                  onChange(selectedOption.value);
                }}
              />
            )}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Search</span>
          </label>
          <div className="input-group">
            <div className="flex flex-nowrap items-center">
              <Controller
                control={control}
                name="search"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <input
                    type="text"
                    placeholder="Search..."
                    className="border rounded-md p-[6.5px] w-max"
                    {...register("search")}
                    onChange={(selectedOption: any) => {
                      setSearch({
                        ...search,
                        text: selectedOption.target.value,
                      });
                    }}
                  />
                )}
              />
              <FontAwesomeIcon icon={faSearch} className="-ml-8 text-black" />
            </div>
            <Controller
              control={control}
              name="searchType"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <select
                  className="border rounded-md p-[6.5px]"
                  {...register("searchType")}
                  onChange={(selectedOption: any) => {
                    setSearch({
                      ...search,
                      type: selectedOption.target.value,
                    });
                  }}
                >
                  <option value={"code"}>po code</option>
                  <option value={"supplier.name"}>supplier</option>
                </select>
              )}
            />
          </div>
        </div>
      </div>
      <br />
      {isLoading === true ? (
        <Loading />
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="table table-compact w-full">
              <thead>
                <tr>
                  <th />
                  <th>PO Code</th>
                  <th>Issued Date</th>
                  <th>Lokasi</th>
                  <th className="text-center">Supplier</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {dataPo.map((data: any, idx: any) => {
                  return (
                    <tr key={idx}>
                      <th></th>
                      <td>{data.code}</td>
                      <td>{data.issued_date}</td>
                      <td>{data.branch}</td>
                      <td className="text-center">{data.supplier?.name}</td>
                      <td className="space-x-2 text-center">
                        <a
                          className="btn btn-info"
                          onClick={() => {
                            handleClick(data);
                          }}
                        >
                          Detail
                        </a>
                        <a
                          className="btn btn-success"
                          href={`${process.env.REACT_APP_PURCHASE_ORDER_EXPORT}/${data.id}`}
                          target={`_blank`}
                        >
                          Export
                        </a>
                        &nbsp;&nbsp;&nbsp;
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-2 md:mb-0">
              <p>
                Showing {totalData.from} to {totalData.to} of {totalData.total}{" "}
                entries
              </p>
            </div>
            <div>
              <div className="btn-group float-right mt-5">
                <button
                  className={
                    pages.prev_page_url === null
                      ? `btn btn-disabled btn-sm`
                      : `btn btn-primary btn-sm`
                  }
                  onClick={prevPage}
                >
                  «
                </button>
                <button className="btn btn-primary btn-sm">
                  Page {pages?.current_page}
                </button>
                <button
                  className={
                    pages.next_page_url === null
                      ? `btn btn-disabled btn-sm`
                      : `btn btn-primary btn-sm`
                  }
                  onClick={nextPage}
                >
                  »
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
