/* eslint-disable no-restricted-globals */
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Controller,
  SubmitHandler,
  useForm,
  useFieldArray,
} from "react-hook-form";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import { Loading } from "../../../components/Loading/Loading";
import { rupiahConvert } from "../../../helper/rupiahConvert";
import { RootState } from "../../../store/store";
Modal.setAppElement("#root");

type IItems = {
  delivery_date: Date | string;
  supplier_invoice_number: string;
  product_id: string;
  qty: number;
  price: number;
};

type IInputsNewPyrq = {
  branch: string;
  po_id: string;
  due_date: Date;
  discount_amount: number;
  assigned_name: string;
  approval_name: string;
  checker_name: string;
  items: IItems[];
};

export default function PaymentRequest() {
  const globalStateUser: any = useSelector((state: RootState) => {
    return state.userData;
  });
  const [inputsForAsyncSelectProductLoad, setInputsForAsyncSelectProductLoad] =
    useState<any>([]);
  const navigate = useNavigate();
  const {
    register: registerNewPyrq,
    handleSubmit: handleSubmitNewPyrq,
    control: controlNewPyrq,
    formState: { errors: errorsNewPyrq },
    setValue: setValueNewPyrq,
    getValues: getValueNewPyrq,
    reset: resetValueNewPyrq,
  } = useForm<IInputsNewPyrq>();
  const {
    fields: fieldProduct,
    append: appendProduct,
    remove: removeProduct,
  } = useFieldArray({
    control: controlNewPyrq,
    name: "items",
  } as never);
  const [dataPyrq, setDataPyrq] = useState([]);
  const [totalData, setTotalData] = useState({
    from: "",
    to: "",
    total: "",
  });
  const [page, setPage] = useState(1);
  const [pageNav, setPageNav] = useState({
    nextPage: "",
    preffPage: "",
  });

  const [isOpenModalNewPyrq, setIsOpenModalNewPyrq] = useState(false);
  function toggleModalNewPyrq(data?: any) {
    setIsOpenModalNewPyrq(!isOpenModalNewPyrq);
  }
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (globalStateUser.user?.role_id !== "accounting") {
      navigate("/product");
    }
    setIsLoading(true);
    axios
      .get((process.env.REACT_APP_PYRQ as string) + `?page=${page}`, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((response) => {
        setDataPyrq(response.data.data.data);
        setTotalData({
          ...totalData,
          from: response.data.data.from,
          to: response.data.data.to,
          total: response.data.data.total,
        });
        setPage(response.data.data.current_page);
        setPageNav({
          ...pageNav,
          nextPage: response.data.data.next_page_url,
          preffPage: response.data.data.prev_page_url,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page]);

  const fetchDataPOList = (inputValue: any, callback: any) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(
          (process.env.REACT_APP_PURCHASE_ORDER_OPEN as string) +
            `?filter[code]=${inputValue}`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            const tempArray: any = [];
            res.data.forEach((element: any) => {
              tempArray.push({
                label: `${element.code}`,
                value: element.id,
              });
            });
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  const fetchDataProductList = (inputValue: any, callback: any) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(
          (process.env.REACT_APP_GET_ALL_PRODUCT_NO_IMAGES as string) +
            `?filter[name]=${inputValue}`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            const tempArray: any = [];
            res.data.forEach((element: any) => {
              tempArray.push({
                label: `${element.name}`,
                value: element.id,
              });
            });
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  const getDetailPO = (id: string) => {
    axios
      .get((process.env.REACT_APP_PURCHASE_ORDER as string) + `/${id}`, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((response) => {
        setInputsForAsyncSelectProductLoad(response.data.data.items);
        setValueNewPyrq("branch", response.data.data.branch);
        setValueNewPyrq(
          "items",
          response.data.data.items.map((data: any) => {
            return {
              product_id: data.product.id,
              qty: data.qty,
              price: data.price,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClick = (data: any) => {
    axios
      .get((process.env.REACT_APP_PYRQ as string) + `/${data?.id}`, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((response) => {
        Swal.fire({
          title: "Detail",
          width: "auto",
          html: `
                <style type="text/css">
                .tg  {border-collapse:collapse;border-color:#9ABAD9;border-spacing:0;}
                .tg td{background-color:#EBF5FF;border-color:#9ABAD9;border-style:solid;border-width:0px;color:#444;
                font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;word-break:normal;}
                .tg th{background-color:#409cff;border-color:#9ABAD9;border-style:solid;border-width:0px;color:#fff;
                font-family:Arial, sans-serif;font-size:14px;font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
                .tg .tg-9wq8{border-color:inherit;text-align:center;vertical-align:middle}
                .center {
                  margin-left: auto;
                  margin-right: auto;
                }
                </style>
                <table class="tg center">
                <thead>
                    <tr>
                    <th class="tg-0lax">Location</th>
                    <th class="tg-0lax">Code</th>
                    <th class="tg-0lax">Supplier</th>
                    <th class="tg-0lax">Generate Date</th>
                    <th class="tg-0lax">Due Date</th>
                    <th class="tg-0lax">Assigned Name</th>
                    <th class="tg-0lax">Gross Amount</th>
                    <th class="tg-0lax">Discount Amount</th>
                    <th class="tg-0lax">Pph 21</th>
                    <th class="tg-0lax">Total Amount</th>
                    <th class="tg-0lax">Approval Name</th>
                    <th class="tg-0lax">Checker Name</th>
                    </tr>
                </thead>
                <tbody>
                ${`
                    <tr>
                        <td class="tg-0lax">${response.data?.data?.branch}</td>
                        <td class="tg-0lax">${response.data?.data?.code}</td>
                        <td class="tg-0lax">${
                          response.data?.data?.supplier?.name
                        }</td>
                        <td class="tg-0lax">${
                          response.data?.data?.generate_date
                        }</td>              
                        <td class="tg-0lax">${
                          response.data?.data?.due_date
                        }</td>              
                        <td class="tg-0lax">${
                          response.data?.data?.assigned_name
                        }</td>              
                        <td class="tg-0lax">Rp ${rupiahConvert(
                          response.data?.data?.gross_amount
                        )}</td>              
                        <td class="tg-0lax">Rp ${rupiahConvert(
                          response.data?.data?.discount_amount
                        )}</td>              
                        <td class="tg-0lax">Rp ${rupiahConvert(
                          response.data?.data?.pph_21
                        )}</td>              
                        <td class="tg-0lax">Rp ${rupiahConvert(
                          response.data?.data?.total_amount
                        )}</td>              
                        <td class="tg-0lax">${
                          response.data?.data?.approval_name
                        }</td>              
                        <td class="tg-0lax">${
                          response.data?.data?.checker_name
                        }</td>                         
                    </tr>
                    `}                          
                </tbody>
                </table>
                <table class="tg center">
                <thead>
                    <th class="tg-0lax">Product</th>
                    <th class="tg-0lax">Price</th>                    
                    </tr>
                </thead>
                <tbody>
                ${response.data?.data?.items.map((data: any) => {
                  return `
                  <tr>
                      <td class="tg-0lax">${data?.product?.name}</td>
                      <td class="tg-0lax">Rp ${rupiahConvert(
                        data?.price
                      )}</td>                      
                  </tr>
                  `;
                })}                          
                </tbody>
                </table>
                `,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNewPyrq: SubmitHandler<IInputsNewPyrq> = (data: any) => {
    const submitData = {
      ...data,
      po_id: data.po_id.value,
      items: data.items.map((data: any) => {
        return {
          supplier_invoice_number: data.supplier_invoice_number,
          delivery_date: data.delivery_date,
          product_id: data.product_id,
          price: data.price,
          qty: data.qty,
        };
      }),
    };
    axios
      .post(process.env.REACT_APP_PYRQ as string, submitData, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Stock telah di tambahkan!",
          showConfirmButton: false,
          timer: 1500,
        });
        location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleViewBPK(pyrqId: string) {
    axios
      .get(process.env.REACT_APP_BPK_BY_PYRQ_EXPORT + `/${pyrqId}`, {
        headers: { Authorization: `Bearer ${globalStateUser.token}` },
        responseType: "blob",
      })
      .then((res) => {
        const file = new Blob([res.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = URL.createObjectURL(file); //Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${error}`,
        });
      });
  }

  return (
    <>
      <Modal
        isOpen={isOpenModalNewPyrq}
        onRequestClose={toggleModalNewPyrq}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-6/12 lg:h-6/6 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
        shouldCloseOnEsc={false}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalNewPyrq}
          >
            ✕
          </label>
          <form onSubmit={handleSubmitNewPyrq(handleNewPyrq)}>
            <label className="label">
              <span className="label-text">PO code</span>
            </label>
            <Controller
              control={controlNewPyrq}
              name="po_id"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <AsyncSelect
                  noOptionsMessage={() => "Type to search a SO list"}
                  {...registerNewPyrq("po_id")}
                  value={value}
                  loadOptions={fetchDataPOList}
                  placeholder={`PO code`}
                  onChange={(e: any) => {
                    onChange(e);
                    getDetailPO(e.value);
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              )}
            />
            {errorsNewPyrq.po_id ? (
              <span className="text-sm text-red-700">Field is required!</span>
            ) : null}
            <label className="label">
              <span className="label-text">Branch</span>
            </label>
            <input
              disabled
              type="text"
              {...registerNewPyrq("branch", { required: true })}
              placeholder="Type here"
              className="border border-slate-300 rounded-md p-[5px] w-full"
            />
            {errorsNewPyrq.branch ? (
              <span className="text-sm text-red-700">Field is required!</span>
            ) : null}
            <label className="label">
              <span className="label-text">Due Date</span>
            </label>
            <input
              type="date"
              {...registerNewPyrq("due_date", { required: true })}
              placeholder="Type here"
              className="border border-slate-300 rounded-md p-[5px] w-full"
            />
            {errorsNewPyrq.due_date ? (
              <span className="text-sm text-red-700">Field is required!</span>
            ) : null}
            <label className="label">
              <span className="label-text">Discount</span>
            </label>
            <input
              type="number"
              {...registerNewPyrq("discount_amount", { required: true })}
              placeholder="Type here"
              className="border border-slate-300 rounded-md p-[5px] w-full"
            />
            {errorsNewPyrq.discount_amount ? (
              <span className="text-sm text-red-700">Field is required!</span>
            ) : null}
            <label className="label">
              <span className="label-text">Assigned Name</span>
            </label>
            <input
              type="text"
              {...registerNewPyrq("assigned_name", { required: true })}
              placeholder="Type here"
              className="border border-slate-300 rounded-md p-[5px] w-full"
            />
            {errorsNewPyrq.assigned_name ? (
              <span className="text-sm text-red-700">Field is required!</span>
            ) : null}
            <label className="label">
              <span className="label-text">Approval Name</span>
            </label>
            <input
              type="text"
              {...registerNewPyrq("approval_name", { required: true })}
              placeholder="Type here"
              className="border border-slate-300 rounded-md p-[5px] w-full"
            />
            {errorsNewPyrq.approval_name ? (
              <span className="text-sm text-red-700">Field is required!</span>
            ) : null}
            <label className="label">
              <span className="label-text">Checker Name</span>
            </label>
            <input
              type="text"
              {...registerNewPyrq("checker_name", { required: true })}
              placeholder="Type here"
              className="border border-slate-300 rounded-md p-[5px] w-full"
            />
            {errorsNewPyrq.checker_name ? (
              <span className="text-sm text-red-700">Field is required!</span>
            ) : null}
            {fieldProduct.map((field, index) => {
              return (
                <div key={field.id}>
                  <div className="divider"></div>
                  <p className="text-md text-center font-bold">
                    Item {index + 1}
                  </p>
                  <div className="flex flex-row justify-between my-2">
                    {/* <div>
                          {index > 0 ? null : (
                            <button
                              type="button"
                              className="btn btn-sm bg-pink-700 border-0"
                              onClick={() =>
                                appendProduct({
                                    delivery_date : "",
                                    supplier_invoice_number : "",
                                    product_id : "",
                                    qty : 0,
                                    price : 0
                                })
                              }
                            >
                              Add Item
                            </button>
                          )}
                        </div> */}
                  </div>
                  <div className="flex flex-col md:flex-row justify-between gap-4">
                    <div className="basis-1/2">
                      <div className="form-control w-full">
                        <label className="label">
                          <span className="label-text">Delivery Date</span>
                        </label>
                        <input
                          type="date"
                          {...registerNewPyrq(`items.${index}.delivery_date`, {
                            required: true,
                          })}
                          placeholder="Type here"
                          className="border rounded-md p-[5px] border-slate-300"
                        />
                        {errorsNewPyrq.items ? (
                          <span className="text-sm text-red-700">
                            Products is required!
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="basis-1/2">
                      <div className="form-control w-full">
                        <label className="label">
                          <span className="label-text">
                            Supplier Invoice Number
                          </span>
                        </label>
                        <input
                          type="text"
                          {...registerNewPyrq(
                            `items.${index}.supplier_invoice_number`,
                            { required: true }
                          )}
                          placeholder="Type here"
                          className="border rounded-md p-[5px] border-slate-300"
                        />
                        {errorsNewPyrq.items ? (
                          <span className="text-sm text-red-700">
                            Products is required!
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between gap-4">
                    <div className="basis-1/3">
                      <label className="label">
                        <span className="label-text">Product</span>
                      </label>
                      <Controller
                        control={controlNewPyrq}
                        {...registerNewPyrq(`items.${index}.product_id`)}
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <AsyncSelect
                            isDisabled
                            className="w-full"
                            noOptionsMessage={() => "Type to search a product"}
                            value={value}
                            loadOptions={fetchDataProductList}
                            placeholder={`${inputsForAsyncSelectProductLoad[index]?.product?.name}`}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                          />
                        )}
                      />
                      {errorsNewPyrq.items ? (
                        <span className="text-sm text-red-700">
                          Products is required!
                        </span>
                      ) : null}
                    </div>
                    <div className="basis-1/3">
                      <div className="form-control w-full">
                        <label className="label">
                          <span className="label-text">Qty</span>
                        </label>
                        <input
                          type="text"
                          {...registerNewPyrq(`items.${index}.qty`, {
                            required: true,
                          })}
                          placeholder="Type here"
                          className="border rounded-md p-[5px] border-slate-300"
                        />
                        {errorsNewPyrq.items ? (
                          <span className="text-sm text-red-700">
                            Products is required!
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="basis-1/3">
                      <div className="form-control w-full">
                        <label className="label">
                          <span className="label-text">Price</span>
                        </label>
                        <input
                          type="text"
                          {...registerNewPyrq(`items.${index}.price`, {
                            required: true,
                          })}
                          placeholder="Type here"
                          className="border rounded-md p-[5px] border-slate-300"
                        />
                        {errorsNewPyrq.items ? (
                          <span className="text-sm text-red-700">
                            Products is required!
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-2 float-right">
                    <button type="button" onClick={() => removeProduct(index)}>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="text-red-700"
                      />
                    </button>
                  </div> */}
                  {/* {index === 0 ? null : (
                    )} */}
                </div>
              );
            })}
            <button className="btn btn-primary mt-4 w-full" type="submit">
              Add PYRQ
            </button>
          </form>
        </div>
      </Modal>
      <button className="btn btn-primary" onClick={toggleModalNewPyrq}>
        Add PYRQ
      </button>
      <div className="mt-6">
        {isLoading === true ? (
          <Loading />
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="table table-compact w-full">
                <thead>
                  <tr>
                    <th />
                    <th>Branch</th>
                    <th>Code</th>
                    <th className="text-center">Generate Date</th>
                    <th className="text-center">Detail</th>
                  </tr>
                </thead>
                <tbody>
                  {dataPyrq.map((data: any, idx: any) => {
                    return (
                      <tr key={idx}>
                        <th></th>
                        <td>{data.branch}</td>
                        <td>{data.code}</td>
                        <td className="text-center">{data.generate_date}</td>
                        <td className="space-x-2 text-center">
                          <a
                            className="btn btn-info"
                            onClick={() => {
                              handleClick(data);
                            }}
                          >
                            Detail
                          </a>
                          <a
                            className="btn btn-success"
                            href={`${process.env.REACT_APP_PYRQ_EXPORT}/${data.id}`}
                            target={`_blank`}
                          >
                            Export
                          </a>
                          <button
                            className="btn btn-success"
                            onClick={() => handleViewBPK(data.id)}
                          >
                            Export BPK
                          </button>
                          &nbsp;&nbsp;&nbsp;
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <div className="mb-2 sm:mb-0">
                <p>
                  Showing {totalData.from} to {totalData.to} of{" "}
                  {totalData.total} entries
                </p>
              </div>
              <div className="btn-group">
                <button
                  className={
                    !pageNav.preffPage
                      ? `btn btn-sm btn-disabled`
                      : `btn btn-sm bg-gray-700 border-0`
                  }
                  onClick={() => {
                    setPage(page - 1);
                  }}
                >
                  «
                </button>
                <button className="btn btn-sm bg-gray-700 border-0">
                  Page {page}
                </button>
                <button
                  className={
                    !pageNav.nextPage
                      ? `btn btn-sm btn-disabled`
                      : `btn btn-sm bg-gray-700 border-0`
                  }
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  »
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
