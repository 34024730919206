import React from "react";
import "./App.css";
import { Drawer } from "./components/Drawer/Drawer";
import { Route, Routes } from "react-router-dom";
import { Report } from "./pages/Report/Report";
import { Login } from "./pages/Login/Login";
import { Proyeksi } from "./pages/Proyeksi/Proyeksi";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { WeekToOrder } from "./pages/WeekToOrder/WeekToOrder";
import { DaftarPO } from "./pages/AccountingPages/DaftarPO/DaftarPO";
import { Transaksi } from "./pages/AccountingPages/Transaksi/Transaksi";
import { Product } from "./pages/AdminPages/Product/Product";
import { ProductType } from "./pages/AdminPages/ProductType/ProductType";
import { InputStock } from "./pages/AccountingPages/InputStock/InputStock";
import { Penjualan } from "./pages/AccountingPages/Penjualan/Penjualan";
import PaymentRequest from "./pages/AccountingPages/PaymentRequest/PaymentRequest";
import Supplier from "./pages/AdminPages/Supplier/Supplier";
import Bpk from "./pages/AccountingPages/Bpk/Bpk";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route element={<Drawer />}>
            {/* <Route path="/history" element={<Report />} />
            <Route path="/proyeksi" element={<Proyeksi />} />
            <Route path="/weekstoorder" element={<WeekToOrder />} /> */}
            <Route path="/daftarpo" element={<DaftarPO />} />
            <Route path="/pyrq" element={<PaymentRequest />} />
            {/* <Route path="/bpk" element={<Bpk />} /> */}
            <Route path="/transaksi" element={<Transaksi />} />
            <Route path="/product" element={<Product />} />
            <Route path="/supplier" element={<Supplier />} />
            <Route path="/type" element={<ProductType />} />
            <Route path="/product-type" element={<ProductType />} />
            <Route path="/input-stock" element={<InputStock />} />
            <Route path="/penjualan" element={<Penjualan />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
