import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Loading } from "../../../components/Loading/Loading";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { rupiahConvert } from "../../../helper/rupiahConvert";
import { useNavigate } from "react-router-dom";

export interface DataOption {
  value: string;
  label: string;
}

export const Penjualan = () => {
  const globalStateUser: any = useSelector((state: RootState) => {
    return state;
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const navigate = useNavigate();
  const {
    // register,
    // handleSubmit,
    control: controlProduct,
    // formState: { errors },
    // setValue,
    // getValues,
  } = useForm();
  const [search, setSearch] = useState({
    text: "",
    type: "taken_code",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [optionsLocation, setOptionsLocation] = useState<DataOption[]>([
    { value: "JTP1", label: "Jatim Park 1" },
    { value: "JTP2", label: "Jatim Park 2" },
    { value: "JTP3", label: "Jatim Park 3" },
    { value: "ECO", label: "Eco Green Park" },
    { value: "SENYUM", label: "Senyum World Hotel" },
    { value: "MGG", label: "Milenial Glow Garden" },
    { value: "PFP", label: "Predator Fun Park" },
    { value: "POHONINN", label: "Pohon Inn Hotel" },
    { value: "MA", label: "Museum Angkut" },
    { value: "BALOGA", label: "Batu Love Garden" },
  ]);
  const [locationFilter, setLocationFilter] = useState("");
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [tenantFilter, setTenantFilter] = useState("");
  const [productFilter, setProductFilter] = useState("");
  const [totalData, setTotalData] = useState({
    from: 0,
    to: 0,
    total: 0,
  });
  const [pageNav, setPageNav] = useState({
    preffPage: "",
    nextPage: "",
  });

  const fetchData = (inputValue: any, callback: any) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(
          (process.env.REACT_APP_SEARCH_TENANT as string) +
            `?filter[shop.name]=${inputValue}`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.userData.token}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            const tempArray: any = [];
            res.data.forEach((element: any) => {
              tempArray.push({
                label: `${element.shop.name}`,
                value: element.id,
              });
            });
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  const fetchDataProduct = (inputValue: any, callback: any) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(
          (process.env.REACT_APP_SEARCH_PRODUCT as string) +
            `?filter[name]=${inputValue}`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.userData.token}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            const tempArray: any = [];
            res.data.forEach((element: any) => {
              tempArray.push({
                label: `${element.name}`,
                value: element.id,
              });
            });
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  useEffect(() => {
    if (globalStateUser.userData?.user?.role_id !== "accounting") {
      navigate("/product");
    }
    setIsLoading(true);
    axios
      .get(
        (process.env.REACT_APP_ACCOUNTING_GET_TRX_SALE as string) +
          `?page=${page}&start=${date.startDate}&end=${
            date.endDate
          }&filter[transaction.branch]=${locationFilter}&filter[${
            search.type === "code"
              ? "transaction.code"
              : "transaction.taken_code"
          }]=${
            search.text
          }&filter[transaction.user.id]=${tenantFilter}&filter[product_id]=${productFilter}`,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.userData.token}`,
          },
        }
      )
      .then((res) => {
        setPageNav({
          nextPage: res.data.data.next_page_url,
          preffPage: res.data.data.prev_page_url,
        });
        setData(res.data.data.data);
        setTotalData({
          from: res.data.data.from,
          to: res.data.data.to,
          total: res.data.data.total,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, date.endDate, locationFilter, search, tenantFilter, productFilter]);

  return (
    <>
      <div className="text-2xl text-center font-bold">Penjualan</div>
      <br />
      <div className="flex flex-col md:flex-row mb-4 gap-2">
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Start Date</span>
          </label>
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <input
                type="date"
                className="border rounded-md p-[5px] border-slate-300"
                onChange={(e: any) => {
                  setDate({ ...date, startDate: e.target.value });
                }}
              />
            )}
          />
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">End Date</span>
          </label>
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <input
                disabled={date.startDate === ""}
                type="date"
                className="border rounded-md p-[5px] border-slate-300"
                onChange={(e: any) => {
                  setDate({ ...date, endDate: e.target.value });
                }}
              />
            )}
          />
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Tenant</span>
          </label>
          <Controller
            control={control}
            name="client_id"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <AsyncSelect
                noOptionsMessage={() => "Type to search a client"}
                value={value}
                loadOptions={fetchData}
                placeholder={`Tenant`}
                onChange={(e: any) => {
                  onChange(e);
                  setTenantFilter(e.value);
                }}
              />
            )}
          />
          {errors.client_id ? (
            <span className="text-sm text-red-700">Field is required!</span>
          ) : null}
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Product</span>
          </label>
          <Controller
            control={controlProduct}
            name="client_id"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <AsyncSelect
                noOptionsMessage={() => "Type to search a client"}
                value={value}
                loadOptions={fetchDataProduct}
                placeholder={`Product`}
                onChange={(e: any) => {
                  onChange(e);
                  setProductFilter(e.value);
                }}
              />
            )}
          />
          {errors.client_id ? (
            <span className="text-sm text-red-700">Field is required!</span>
          ) : null}
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Location</span>
          </label>
          <Controller
            control={control}
            name="location_id"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                isClearable
                value={optionsLocation.find((c) => c.value === value)}
                name={name}
                options={optionsLocation}
                onChange={(selectedOption: any) => {
                  setLocationFilter(selectedOption.value);
                  onChange(selectedOption.value);
                }}
              />
            )}
          />
          {errors.location_id ? (
            <span className="text-sm text-red-700">Field is required!</span>
          ) : null}
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Search</span>
          </label>
          <div className="input-group">
            <div className="flex flex-col items-center mr-2">
              <div className="flex flex-nowrap items-center">
                <Controller
                  control={control}
                  name="search"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <input
                      type="text"
                      placeholder="Search..."
                      className="border rounded-md p-[6.5px] w-max border-slate-300"
                      {...register("search")}
                      onChange={(selectedOption: any) => {
                        setSearch({
                          ...search,
                          text: selectedOption.target.value,
                        });
                      }}
                    />
                  )}
                />
                <FontAwesomeIcon icon={faSearch} className="-ml-8 text-black" />
              </div>
            </div>
            <Controller
              control={control}
              name="searchType"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <select
                  className="border rounded-md p-[6.5px] border-slate-300"
                  {...register("searchType")}
                  onChange={(selectedOption: any) => {
                    setSearch({
                      ...search,
                      type: selectedOption.target.value,
                    });
                  }}
                >
                  <option value="taken_code">Taken Id</option>
                  <option value="code">Kode Transaksi</option>
                </select>
              )}
            />
          </div>
        </div>
        <div className="mt-8">
          <a
            className={
              isLoading
                ? `btn btn-disabled shadow-lg`
                : `btn bg-green-700 border-0 text-white shadow-lg`
            }
            href={
              (process.env.REACT_APP_EXPORT_SALE as string) +
              `?page=${page}&start=${date.startDate}&end=${
                date.endDate
              }&filter[transaction.branch]=${locationFilter}&filter[${
                search.type === "code"
                  ? "transaction.code"
                  : "transaction.taken_code"
              }]=${
                search.text
              }&filter[transaction.user.id]=${tenantFilter}&filter[product_id]=${productFilter}`
            }
            target="_blank"
          >
            <div className="flex flex-row items-center">
              <div>
                <FontAwesomeIcon icon={faFileExcel} size="lg" />
              </div>
              <div>&nbsp;&nbsp;Export</div>
            </div>
          </a>
        </div>
      </div>
      <div className="overflow-x-auto">
        {isLoading === true ? (
          <Loading />
        ) : (
          <table className="table table-compact w-full text-center border-collapse border border-slate-400">
            <thead>
              <tr>
                <th className="sticky left-0 z-10 border border-b-slate-300 border-r-0" />
                <th className="border border-slate-300 border-l-0">
                  Tanggal Order
                </th>
                <th className="border border-slate-300">Nama Park</th>
                <th className="border border-slate-300">Tenant</th>
                <th className="border border-slate-300">Taken ID</th>
                <th className="border border-slate-300">Kode Transaksi</th>
                <th className="border border-slate-300">Item</th>
                <th className="border border-slate-300">Qty</th>
                <th className="border border-slate-300">Price</th>
                <th className="border border-slate-300">Total Price</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data: any, idx) => {
                return (
                  <tr key={idx} className="border border-b-slate-300">
                    <td />
                    <td>{data.order_date}</td>
                    <td>{data.branch}</td>
                    <td>{data.tenant}</td>
                    <td>{data.taken_code === null ? "-" : data.taken_code}</td>
                    <td>{data.code}</td>
                    <td>{data.item}</td>
                    <td>{data.qty}</td>
                    <td>Rp {rupiahConvert(data.price)}</td>
                    <td>Rp {rupiahConvert(data.total_price)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center mt-2">
        <div>
          <p>
            Showing {totalData.from} to {totalData.to} of {totalData.total}{" "}
            entries
          </p>
        </div>
        <div>
          <div className="btn-group">
            <button
              className={
                pageNav.preffPage ? "btn btn-primary" : `btn btn-disabled`
              }
              onClick={() => {
                setPage(page - 1);
              }}
            >
              «
            </button>
            <button className="btn btn-primary">Page {page}</button>
            <button
              className={
                pageNav.nextPage ? "btn btn-primary" : `btn btn-disabled`
              }
              onClick={() => {
                setPage(page + 1);
              }}
            >
              »
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
