import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export interface DataOption {
  value: string;
  label: string;
}

export const InputStock = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const globalStateUser: any = useSelector((state: RootState) => {
    return state.userData;
  });
  const token = globalStateUser.token;
  const [options, setOptions] = useState<DataOption[]>([]);
  const navigate = useNavigate();

  const onSubmit = handleSubmit((data) => {
    const dataInput = {
      branch: data.branch,
      po_code: data.po_code,
      date_input: data.date_input,
      product_id: data.product_id,
      total: parseInt(data.total),
    };
    Swal.fire({
      title: `Yakin ingin menambahkan stock?`,
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      // denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios
          .post(process.env.REACT_APP_INPUT_STOCK as string, dataInput, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Stock telah di tambahkan!",
              showConfirmButton: false,
              timer: 1500,
            });
            reset();
            navigate("/");
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // else if (result.isDenied) {
      //   Swal.fire('Changes are not saved', '', 'info')
      // }
    });
  });

  useEffect(() => {
    if (globalStateUser.user?.role_id !== "accounting") {
      navigate("/product");
    }
    axios
      .get(process.env.REACT_APP_GET_ALL_PRODUCT as string, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        response.data?.data?.forEach((data: any) => {
          setOptions((prevState) => [
            ...prevState,
            {
              value: data.id,
              label: data.name,
            },
          ]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Product</span>
          </label>
          <Controller
            control={control}
            name="product_id"
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                value={options.find((c) => c.value === value)}
                name={name}
                options={options}
                onChange={(selectedOption: any) => {
                  onChange(selectedOption.value);
                }}
              />
            )}
          />
          <div className="label justify-start">
            {errors.product_id ? (
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="text-error mr-2"
              />
            ) : (
              ""
            )}
            <span className="text-error text-sm font-bold">
              {errors.product_id?.type === "required" && "Product required"}
            </span>
          </div>
          <label className="label">
            <span className="label-text">
              Total (Jumlah yang akan ditambahkan)
            </span>
          </label>
          <input
            type="number"
            placeholder="Total (Jumlah yang akan ditambahkan)"
            className={`${
              !errors.total?.type
                ? "input input-bordered"
                : "input border-2 border-error"
            }`}
            {...register("total", { required: true })}
          />
          <div className="label justify-start">
            {errors.total ? (
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="text-error mr-2"
              />
            ) : (
              ""
            )}
            <span className="text-error text-sm font-bold">
              {errors.total?.type === "required" && "Total required"}
            </span>
          </div>
          <div>
            <label className="label">
              <span className="label-text">Lokasi</span>
            </label>
            <select
              className={`${
                !errors.branch?.type
                  ? "select select-bordered w-full"
                  : "select select-error w-full"
              }`}
              {...register("branch", { required: true })}
            >
              <option value="" selected>
                Lokasi
              </option>
              <option value="JTP1">Jatim Park 1</option>
              <option value="JTP2">Jatim Park 2</option>
              <option value="JTP3">Jatim Park 3</option>
              <option value="ECO">Eco Green Park</option>
              <option value="SENYUM">Senyum World Hotel</option>
              <option value="MGG">Milenial Glow Garden</option>
              <option value="PFP">Predator Fun Park</option>
              <option value="POHONINN">Pohon Inn Hotel</option>
              <option value="MA">Museum Angkut</option>
              <option value="BALOGA">Batu Love Garden</option>
            </select>
          </div>
          <div className="label justify-start">
            {errors.branch ? (
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="text-error mr-2"
              />
            ) : (
              ""
            )}
            <span className="text-error text-sm font-bold">
              {errors.branch?.type === "required" && "Lokasi required"}
            </span>
          </div>
          <div>
            <label className="label">
              <span className="label-text">Tanggal Barang Masuk</span>
            </label>
            <input
              type="date"
              className={
                errors.date_input?.type
                  ? "border border-red-500 p-2 rounded-md w-full"
                  : "border border-slate-300 p-2 rounded-md w-full"
              }
              {...register("date_input", { required: true })}
            ></input>
          </div>
          <div className="label justify-start">
            {errors.date_input ? (
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="text-error mr-2"
              />
            ) : (
              ""
            )}
            <span className="text-error text-sm font-bold">
              {errors.date_input?.type === "required" &&
                "Tanggal Barang Masuk required"}
            </span>
          </div>
          <label className="label">
            <span className="label-text">PO Code</span>
          </label>
          <input
            type="text"
            placeholder="po code"
            className={`${
              !errors.po_code?.type
                ? "input input-bordered"
                : "input border-2 border-error"
            }`}
            {...register("po_code")}
          />
          <div className="label justify-start">
            {errors.po_code ? (
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="text-error mr-2"
              />
            ) : (
              ""
            )}
            <span className="text-error text-sm font-bold">
              {errors.po_code?.type === "required" && "PO Code required"}
            </span>
          </div>
          <label className="label"></label>
          <div className="mt-6">
            <button className="btn btn-primary w-full" type="submit">
              Input Stock
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
