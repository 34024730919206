/* eslint-disable no-restricted-globals */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Loading } from "../../../components/Loading/Loading";
import { RootState } from "../../../store/store";
Modal.setAppElement("#root");

type IInputsNewSupplier = {
  id?: string;
  name: string;
  bank: string;
  account_name: string;
  account_number: string;
  phone: string;
  address: string;
  city: string;
  email: string;
  pic: string;
  pic_position: string;
};

export default function Supplier() {
  const globalStateUser: any = useSelector((state: RootState) => {
    return state.userData;
  });
  const navigate = useNavigate();
  const {
    register: registerNewSupplier,
    handleSubmit: handleSubmitNewSupplier,
    control: controlNewSupplier,
    formState: { errors: errorsNewSupplier },
    setValue: setValueNewSupplier,
    getValues: getValueNewSupplier,
    reset: resetValueNewSupplier,
  } = useForm<IInputsNewSupplier>();
  const [dataSupplier, setDataSupplier] = useState([]);
  const [totalData, setTotalData] = useState({
    from: "",
    to: "",
    total: "",
  });
  const [page, setPage] = useState(1);
  const [pageNav, setPageNav] = useState({
    nextPage: "",
    preffPage: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalNewSupplier, setIsOpenModalNewSupplier] = useState(false);
  function toggleModalNewSupplier(data?: any) {
    if (data.id) {
      setValueNewSupplier("id", data.id);
      setValueNewSupplier("name", data.name);
      setValueNewSupplier("bank", data.bank);
      setValueNewSupplier("account_name", data.account_name);
      setValueNewSupplier("account_number", data.account_number);
      setValueNewSupplier("phone", data.phone);
      setValueNewSupplier("address", data.address);
      setValueNewSupplier("city", data.city);
      setValueNewSupplier("email", data.email);
      setValueNewSupplier("pic", data.pic);
      setValueNewSupplier("pic_position", data.pic_position);
    } else {
      resetValueNewSupplier();
    }
    setIsOpenModalNewSupplier(!isOpenModalNewSupplier);
  }

  const handleNewSupplier: SubmitHandler<IInputsNewSupplier> = (data: any) => {
    const { id, ...dataPost } = data; //Except id
    if (data.id) {
      axios
        .put(
          (process.env.REACT_APP_SUPPLIER as string) + `/${data.id}`,
          dataPost,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
        .then((response) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Data supplier telah diedit",
            showConfirmButton: false,
            timer: 1500,
          });
          location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(process.env.REACT_APP_SUPPLIER as string, dataPost, {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        })
        .then((response) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Data supplier telah ditambahkan",
            showConfirmButton: false,
            timer: 1500,
          });
          location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (globalStateUser.user?.role_id === "accounting") {
      navigate("/transaksi");
    }
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_SUPPLIER as string, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((response) => {
        setDataSupplier(response.data.data.data);
        setTotalData({
          ...totalData,
          from: response.data.data.from,
          to: response.data.data.to,
          total: response.data.data.total,
        });
        setPage(response.data.data.current_page);
        setPageNav({
          ...pageNav,
          nextPage: response.data.data.next_page_url,
          preffPage: response.data.data.prev_page_url,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      <button className="btn btn-primary" onClick={toggleModalNewSupplier}>
        Add Supplier
      </button>
      <Modal
        isOpen={isOpenModalNewSupplier}
        onRequestClose={toggleModalNewSupplier}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-6/12 lg:h-6/6 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
        shouldCloseOnEsc={false}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalNewSupplier}
          >
            ✕
          </label>
          <div>
            <h3 className="font-bold text-xl mb-3">Form Supplier</h3>
            <form onSubmit={handleSubmitNewSupplier(handleNewSupplier)}>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Nama</span>
                </label>
                <input
                  type="text"
                  placeholder="Nama..."
                  className="input input-bordered w-full"
                  {...registerNewSupplier("name", { required: true })}
                />
                {errorsNewSupplier.name ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <label className="label">
                  <span className="label-text">Bank</span>
                </label>
                <input
                  type="text"
                  placeholder="Bank..."
                  className="input input-bordered w-full"
                  {...registerNewSupplier("bank", { required: true })}
                />
                {errorsNewSupplier.bank ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <label className="label">
                  <span className="label-text">Account Name</span>
                </label>
                <input
                  type="text"
                  placeholder="Account Name..."
                  className="input input-bordered w-full"
                  {...registerNewSupplier("account_name", { required: true })}
                />
                {errorsNewSupplier.account_name ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <label className="label">
                  <span className="label-text">Account Number</span>
                </label>
                <input
                  type="text"
                  placeholder="Account Number..."
                  className="input input-bordered w-full"
                  {...registerNewSupplier("account_number", { required: true })}
                />
                {errorsNewSupplier.account_number ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <label className="label">
                  <span className="label-text">Phone</span>
                </label>
                <input
                  type="text"
                  placeholder="Phone..."
                  className="input input-bordered w-full"
                  {...registerNewSupplier("phone", { required: true })}
                />
                {errorsNewSupplier.phone ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <label className="label">
                  <span className="label-text">Address</span>
                </label>
                <input
                  type="text"
                  placeholder="Address..."
                  className="input input-bordered w-full"
                  {...registerNewSupplier("address", { required: true })}
                />
                {errorsNewSupplier.address ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <label className="label">
                  <span className="label-text">City</span>
                </label>
                <input
                  type="text"
                  placeholder="City..."
                  className="input input-bordered w-full"
                  {...registerNewSupplier("city", { required: true })}
                />
                {errorsNewSupplier.city ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <label className="label">
                  <span className="label-text">Email</span>
                </label>
                <input
                  type="text"
                  placeholder="Email..."
                  className="input input-bordered w-full"
                  {...registerNewSupplier("email", { required: true })}
                />
                {errorsNewSupplier.email ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <label className="label">
                  <span className="label-text">Pic</span>
                </label>
                <input
                  type="text"
                  placeholder="Pic..."
                  className="input input-bordered w-full"
                  {...registerNewSupplier("pic", { required: true })}
                />
                {errorsNewSupplier.pic ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <label className="label">
                  <span className="label-text">Pic Position</span>
                </label>
                <input
                  type="text"
                  placeholder="Pic Position..."
                  className="input input-bordered w-full"
                  {...registerNewSupplier("pic_position", { required: true })}
                />
                {errorsNewSupplier.pic_position ? (
                  <span className="text-sm text-red-700">
                    Field is required!
                  </span>
                ) : null}
                <button className="btn btn-primary mt-2" type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <div className="mt-6">
        {isLoading === true ? (
          <Loading />
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="table table-compact w-full">
                <thead>
                  <tr>
                    <th />
                    <th>Name</th>
                    <th>Code</th>
                    <th>Account Number</th>
                    <th>Account Name</th>
                    <th>Bank</th>
                    <th>City</th>
                    <th>Phone</th>
                    <th>PIC</th>
                    <th>PIC Position</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {dataSupplier.map((data: any, idx: any) => {
                    return (
                      <tr key={idx}>
                        <th></th>
                        <td>{data?.name}</td>
                        <td>{data?.code}</td>
                        <td>{data?.account_name}</td>
                        <td>{data?.account_number}</td>
                        <td>{data?.bank}</td>
                        <td>{data?.city}</td>
                        <td>{data?.phone}</td>
                        <td>{data?.pic}</td>
                        <td>{data?.pic_position}</td>
                        <td>
                          <button
                            className="btn btn-warning"
                            onClick={() => toggleModalNewSupplier(data)}
                          >
                            Edit
                          </button>
                        </td>
                        {/* <td>
                          <a
                            className="btn btn-info"
                            onClick={() => {
                              handleClick(data);
                            }}
                          >
                            Detail
                          </a>
                          &nbsp;&nbsp;&nbsp;
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <div className="mb-2 sm:mb-0">
                <p>
                  Showing {totalData.from} to {totalData.to} of{" "}
                  {totalData.total} entries
                </p>
              </div>
              <div className="btn-group">
                <button
                  className={
                    !pageNav.preffPage
                      ? `btn btn-sm btn-disabled`
                      : `btn btn-sm bg-gray-700 border-0`
                  }
                  onClick={() => {
                    setPage(page - 1);
                  }}
                >
                  «
                </button>
                <button className="btn btn-sm bg-gray-700 border-0">
                  Page {page}
                </button>
                <button
                  className={
                    !pageNav.nextPage
                      ? `btn btn-sm btn-disabled`
                      : `btn btn-sm bg-gray-700 border-0`
                  }
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  »
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
